// src/redux/slices/dataSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { gql } from "@apollo/client";
import { client } from "index";

interface User {
  id: string;
  name: string;
  role: string;
  emails: string[];
  entityName: string;
  entityId: number;
  isAdmin: boolean;
}
export interface Entity {
  email: string;
  entityId: string;
  name: string;
  firstName: string;
  entityName: string;
  entityType: string;
  shareStrategyIds: number[];
  loanStrategyIds: number[];
}

export interface Stakeholder {
  ownerId: number;
  partitionKey: string | null;
  shares: StakeholderShare[] | null;
  loanShares: StakeholderShare[] | null;
  loans: StakeholderLoan[] | null;
  commitments: StakeholderCommitment[] | null;
  certificates: StakeholderCertificate[] | null;
}

interface StakeholderCertificate {
  strategyId: number;
  ownerId: number;
  amount: number;
  secClassName: string | null;
}

export interface StakeholderShare {
  shareId: number;
  strategyId: number;
  shareTransactions: StakeholderShareTransaction[] | null;
}

export interface StakeholderLoan {
  loanId: number;
  strategyId: number;
  principalAmount: number;
  startDate: Date;
  interestRate: number;
  accrualPeriod: string | null;
  witholdingTax: string | null;
  loanCalculations: (StakeholderLoanCalculation | null)[] | null;
}

interface StakeholderCommitment {
  id: number;
  entityState: number;
  strategyId: number;
  holder: StakeholderCommitmentHolder | null;
  totalSubscribedAmount: number;
  totalCommittedAmount: number;
  totalPaidUpCapital: number;
  totalPaidUpShareCapital: number;
  totalPaidUpAmount: number;
  totalPaidUpSharePremium: number;
  totalPaidUpShareEquity: number;
  subscribedShareCapital: number;
  subscribedSharePremium: number;
  subscribedShareEquity: number;
  totalLentAmount: number;
  totalOutstandingCommittedAmount: number;
}

interface StakeholderCommitmentHolder {
  ownerId: number;
  ownerName: string | null;
  ownerFirstName: string | null;
  committedAmount: number;
  subscribedAmount: number;
  subscribedEquity: number;
  subscribedCapital: number;
  subscribedPremium: number;
  paidUpCommitment: number;
  percentagePaidUpCommitment: number;
  paidUpCapital: number;
  paidUpPremium: number;
  paidUpEquity: number;
  lentAmount: number;
  outstandingCommittedAmount: number;
  endDate: string | null;
  startDate: string | null;
}

interface StakeholderLoanCalculation {
  id: number;
  loanId: number;
  validFrom: Date;
  partitionKey: string | null;
  totalGrossInterest: number;
  totalWithHoldingTax: string | null;
  totalNetInterest: number;
  outstandingPrincipalAmount: number;
  outstandingNetInterest: number;
  totalOutstandingAmount: number;
  calculationSteps: StakeholderLoanCalculationStep[] | null;
}

export interface StakeholderLoanCalculationStep {
  date: Date;
  type: string | null;
  amount: number;
  grossInterest: number;
  withHoldingTax: string | null;
  netInterest: number;
  totalGrossInterest: number;
  totalWithHoldingTax: string | null;
  totalNetInterest: number;
  outstandingPrincipalAmount: number;
  outstandingNetInterest: number;
  totalOutstandingAmount: number;
}

export interface StakeholderLoanCalculationStepWithLoan
  extends StakeholderLoanCalculationStep {
  loan: StakeholderLoan;
}

export interface StakeholderShareTransaction {
  transferDirection: string;
  id: string;
  transactionId: number;
  shareId: number;
  strategyId: number;
  partitionKey: string | null;
  assetType: string | null;
  type: string | null;
  actionType: string | null;
  shareValue: number;
  shareCapital: number;
  shareCapitalSubscribed: number;
  shareCapitalPaidUp: number;
  shareCapitalUnpaid: number;
  sharePremium: number;
  sharePremiumSubscribed: number;
  sharePremiumPaidUp: number;
  sharePremiumUnpaid: number;
  transactionDate: string;
  description: string;
  holder: StakeholderShareTransactionHolder | null;
}

interface StakeholderShareTransactionHolder {
  ownerName: string | null;
  ownerFirstName: string | null;
  amount: number;
  ownerId: number;
  numberOfSecurities: number;
  purchasePrice: number;
  securityClass: string | null;
  grossDividend: number;
  sharePremiumSubscribed: number;
  sharePremiumPayouts: number;
  sharePremiumUnpaid: number;
  sharePremiumPaidUp: number;
  capitalEquitySubscribed: number;
  capitalEquityPayouts: number;
  capitalEquityUnpaid: number;
  capitalEquityPaidUp: number;
  totalPaidUpAmount: number;
  totalAmountToPay: number;
  shareCapitalPaidUp: number;
  shareCapitalUnpaid: number;
  capitalPayouts: number;
}

interface Price {
  type: string;
  price: number;
  calculationDate: Date;
}

interface CombinedValue {
  investment: string;
  rights: string;
  entityId: number;
  type: string;
  loanType: string;
  prices?: Price[];
}

interface Combined {
  name: string;
  values: CombinedValue[];
}

interface StrategyMappingSetting {
  strategy: string;
  combined: Combined[];
}

export interface StrategyMapping {
  values: StrategyMappingSetting[];
}
export interface StrategyValues {
  [key: string]: number;
}

interface TranslationMappingSetting {
  type: string;
  original: string;
  translated: string;
}

export interface TranslationMapping {
  transactions: TranslationMappingSetting[];
}

interface SecurityTypeMappingSettingMapping {
  original: string;
  translated: string;
}

export interface SecurityTypeMappingSetting {
  description: string;
  strategyIds: number[];
  mappings: SecurityTypeMappingSettingMapping[];
}

export interface SecurityTypeMapping {
  securityTypes: SecurityTypeMappingSetting[];
}

export interface UserEmails {
  id: string;
  userId: number;
  email: string;
}
interface DataState {
  selectedEntityIds: number[] | null;
  selectedEntityNames: string[] | null;
  selectedEntityBreadcrumb: string[];
  users: User[];
  usersByOriginalEmail: User[];
  entitiesbyEmail: Entity[];
  loading: boolean;
  error: string | null;
  strategyMappingSettings: StrategyMapping;
  translationMappingSettings: TranslationMapping;
  securityTypeMappingSettings: SecurityTypeMapping;
  stakeholders: Stakeholder[];
  selectedStrategyIds: number[];
  portfolioDetailsValues: (string | number | object)[][];
  selectedDate: string | null;
  selectedStrategyState: {
    strategyName: string;
    level: number;
  };
  emailsToImpersonate: UserEmails[];
  selectedEmail: string;
  originalEmail: string;
}
const initialState: DataState = {
  users: [],
  usersByOriginalEmail: [],
  entitiesbyEmail: [],
  loading: false,
  error: null,
  selectedEntityIds: [],
  selectedEntityNames: [],
  strategyMappingSettings: { values: [] },
  stakeholders: [],
  selectedStrategyIds: [],
  portfolioDetailsValues: [],
  selectedDate: null,
  translationMappingSettings: { transactions: [] },
  securityTypeMappingSettings: { securityTypes: []},
  selectedEntityBreadcrumb: ['All strategies'],
  selectedStrategyState: {
    strategyName: "All strategies",
    level: 0
  },
  emailsToImpersonate:[],
  selectedEmail: "",
  originalEmail: ""
};

const GET_USERSBYEMAIL = gql`
  query usersbyEmail($email: String!) {
    usersByEmail(email: $email) {
      id
      name
      role
      entityName
      entityId
      isAdmin
    }
  }
`;

const GET_ENTITIESBYEMAIL = gql`
  query entitiesbyEmail($userIds: [Int!]!, $role: String!) {
    entitiesbyEmail(userIds: $userIds, role: $role) {
      entityId
      firstName
      name
      entityName
      entityType
      shareStrategyIds
      loanStrategyIds
    }
  }
`;

const GET_STRATEGYMAPPINGSETTINGS = gql`
  query strategyMappingSettings {
    strategyMappingSettings {
      values {
        strategy
        combined {
          name
          values {
            entityId
            investment
            type
            loanType
            prices {
              type
              price
              calculationDate
            }
          }
        }
      }
    }
  }
`;

const GET_TRANSLATIONMAPPINGSETTINGS = gql`
  query translationMappingSettings {
    translationMappingSettings {
      transactions {
        type
        original
        translated
      }
    }
  }
`;

const GET_SECURITYTYPEMAPPINGSETTINGS = gql`
  query securityTypeMappingSettings {
    securityTypeMappingSettings {
      securityTypes {
        description
        strategyIds
        mappings {
          original
          translated
        }
      }
    }
  }
`;

const GET_STAKEHOLDERS = gql`
  query stakeholders($ids: [Int!]!) {
    stakeholders(entityIds: $ids) {
      ownerId
      partitionKey
      commitments {
        entityState
        id
        strategyId
        subscribedShareCapital
        subscribedShareEquity
        subscribedSharePremium
        totalCommittedAmount
        totalLentAmount
        totalOutstandingCommittedAmount
        totalPaidUpAmount
        totalPaidUpCapital
        totalPaidUpShareCapital
        totalPaidUpShareEquity
        totalPaidUpSharePremium
        totalSubscribedAmount
        holder {
          committedAmount
          endDate
          lentAmount
          outstandingCommittedAmount
          ownerFirstName
          ownerId
          ownerName
          paidUpCapital
          paidUpCommitment
          paidUpEquity
          paidUpPremium
          percentagePaidUpCommitment
          startDate
          subscribedAmount
          subscribedCapital
          subscribedEquity
          subscribedPremium
        }
      }
      loans {
        loanId
        strategyId
        principalAmount
        startDate
        interestRate
        accrualPeriod
        witholdingTax
        loanCalculations {
          id
          loanId
          outstandingNetInterest
          outstandingPrincipalAmount
          partitionKey
          totalGrossInterest
          totalNetInterest
          totalOutstandingAmount
          totalWithHoldingTax
          validFrom
          calculationSteps {
            amount
            date
            grossInterest
            netInterest
            outstandingNetInterest
            outstandingPrincipalAmount
            totalGrossInterest
            totalNetInterest
            totalOutstandingAmount
            totalWithHoldingTax
            type
            withHoldingTax
          }
        }
      }
      shares {
        shareId
        strategyId
        shareTransactions {
          actionType
          assetType
          id
          partitionKey
          shareCapital
          shareCapitalPaidUp
          shareCapitalSubscribed
          shareCapitalUnpaid
          shareId
          sharePremium
          sharePremiumPaidUp
          sharePremiumSubscribed
          sharePremiumUnpaid
          shareValue
          strategyId
          transactionDate
          description
          transactionId
          type
          transferDirection
          holder {
            amount
            numberOfSecurities
            ownerFirstName
            ownerId
            ownerName
            securityClass
            grossDividend
            sharePremiumSubscribed
            sharePremiumPayouts
            sharePremiumUnpaid
            sharePremiumPaidUp
            capitalEquitySubscribed
            capitalEquityPayouts
            capitalEquityUnpaid
            capitalEquityPaidUp
            purchasePrice
            totalPaidUpAmount
            totalAmountToPay
            shareCapitalPaidUp
            shareCapitalUnpaid
            capitalPayouts
          }
        }
      }
      certificates {
        amount
        ownerId
        secClassName
        strategyId
      }
      loanShares {
        shareId
        strategyId
        shareTransactions {
          actionType
          assetType
          id
          partitionKey
          shareCapital
          shareCapitalPaidUp
          shareCapitalSubscribed
          shareCapitalUnpaid
          shareId
          sharePremium
          sharePremiumPaidUp
          sharePremiumSubscribed
          sharePremiumUnpaid
          shareValue
          strategyId
          transactionDate
          description
          transactionId
          type
          holder {
            amount
            numberOfSecurities
            ownerFirstName
            ownerId
            ownerName
            securityClass
            grossDividend
            sharePremiumSubscribed
            sharePremiumPayouts
            sharePremiumUnpaid
            sharePremiumPaidUp
            capitalEquitySubscribed
            capitalEquityPayouts
            capitalEquityUnpaid
            capitalEquityPaidUp
            purchasePrice
          }
        }
      }
    }
  }
`;



const GET_ALLEMAILSOFUSERS = gql`
  query allEmailsofUsers {
    allEmailsofUsers {
      id
      userId
      email
    }
  }
`;


export const fetchusersByEmail = createAsyncThunk(
  "data/fetchusersByEmail",
  async (email: string) => {
    const response = await client.query({
      query: GET_USERSBYEMAIL,
      variables: { email },
    });
    return response.data.usersByEmail as User[];
  }
);

export const fetchEntitiesByEmail = createAsyncThunk(
  "data/fetchEntitiesByEmail",
  async ({ userIds, role }: { userIds: string[]; role: string }) => {
    const response = await client.query({
      query: GET_ENTITIESBYEMAIL,
      variables: { userIds, role },
    });
    return response.data.entitiesbyEmail as Entity[];
  }
);

export const fetchStrategyMappingSettings = createAsyncThunk(
  "data/fetchStrategyMappingSettings",
  async () => {
    const response = await client.query({
      query: GET_STRATEGYMAPPINGSETTINGS,
    });
    return response.data.strategyMappingSettings as StrategyMapping;
  }
);

export const fetchTranslationMappingSettings = createAsyncThunk(
  "data/fetchTranslationMappingSettings",
  async () => {
    const response = await client.query({
      query: GET_TRANSLATIONMAPPINGSETTINGS,
    });
    return response.data.translationMappingSettings as TranslationMapping;
  }
);

export const fetchSecurityTypeMappingSettings = createAsyncThunk(
  "data/fetchSecurityTypeMappingSettings",
  async () => {
    const response = await client.query({
      query: GET_SECURITYTYPEMAPPINGSETTINGS,
    });
    return response.data.securityTypeMappingSettings as SecurityTypeMapping;
  }
);

export const fetchStakeholders = createAsyncThunk(
  "data/fetchStakeholders",
  async ({ ownerIds }: { ownerIds: number[] }) => {
    const response = await client.query({
      query: GET_STAKEHOLDERS,
      variables: { ids: ownerIds },
    });
    return response.data.stakeholders as Stakeholder[];
  }
);
export const fetchAllEmailsofUsers = createAsyncThunk(
  "data/fetchAllEmailsofUsers",
  async () => {
    const response = await client.query({
      query: GET_ALLEMAILSOFUSERS,
    });
    return response.data.allEmailsofUsers as UserEmails[];
  }
);
const getEntityNames = (ids: number[], entities: Entity[]) => {
  return ids
    .map((id) => {
      const entity = entities.find((e) => +e.entityId === id);
      return entity ? entity.entityName : id.toString(); // Fallback to ID as string
    })
    .join(", ");
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setSelectedEntityIds(state, action: PayloadAction<number[]>) {
      state.selectedEntityIds = Array.from(new Set(action.payload));
    },
    setSelectedEntityNames(state, action: PayloadAction<string[]>) {
      state.selectedEntityNames = action.payload;
    },
    setSelectedStrategyids(state, action: PayloadAction<number[]>) {
      state.selectedStrategyIds = Array.from(new Set(action.payload));
    },
    resetSelectedEntityIds: (state) => {
      state.selectedEntityIds = [];
    },
    resetSelectedStrategyIds: (state) => {
      state.selectedStrategyIds = Array.from(
        new Set(
          state.strategyMappingSettings.values
            .flatMap((x) => x.combined)
            .flatMap((x) => x.values)
            .map((x) => x.entityId)
        )
      );
    },
    setPortfolioDetails(
      state,
      action: PayloadAction<(string | number | object)[][]>
    ) {
      state.portfolioDetailsValues = action.payload;
    },
    setSelectedDate(state, action: PayloadAction<string>) {
      let date = new Date(action.payload);
      date.setDate(date.getDate() + 1);
      state.selectedDate = date.toDateString();
    },
    setSelectedEntityBreadcrumb(state, action: PayloadAction<string[]>) {
      state.selectedEntityBreadcrumb = action.payload;
    },
    setSelectedStrategyState(state, action: PayloadAction<{
      strategyName: string;
      level: number;
    }>) {
      state.selectedStrategyState = action.payload;
    },
    setSelectedEmail(state, action: PayloadAction<string>) {
      state.selectedEmail = action.payload;
    },
    setOriginalEmail(state, action: PayloadAction<string>) {
      state.originalEmail = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchusersByEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchusersByEmail.fulfilled,
        (state, action: PayloadAction<User[]>) => {
          state.loading = false;
          if (state.selectedEmail === state.originalEmail) {
            state.usersByOriginalEmail = action.payload;
          }
          state.users = action.payload;
        }
      )
      .addCase(fetchusersByEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch users";
      })
      .addCase(fetchEntitiesByEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchEntitiesByEmail.fulfilled,
        (state, action: PayloadAction<Entity[]>) => {
          state.loading = false;
          state.entitiesbyEmail = action.payload;
        }
      )
      .addCase(fetchEntitiesByEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch entities";
      })
      .addCase(fetchStakeholders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchStakeholders.fulfilled,
        (state, action: PayloadAction<Stakeholder[]>) => {
          state.loading = false;
          state.stakeholders = action.payload;
        }
      )
      .addCase(fetchStakeholders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch stakeholders";
      })
      .addCase(fetchStrategyMappingSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchStrategyMappingSettings.fulfilled,
        (state, action: PayloadAction<StrategyMapping>) => {
          state.loading = false;
          state.strategyMappingSettings = action.payload;
          state.selectedStrategyIds = state.strategyMappingSettings.values
            .flatMap((x) => x.combined)
            .flatMap((x) => x.values)
            .map((x) => x.entityId);
        }
      )
      .addCase(fetchStrategyMappingSettings.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch translation mappings";
      })
      .addCase(fetchTranslationMappingSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTranslationMappingSettings.fulfilled,
        (state, action: PayloadAction<TranslationMapping>) => {
          state.loading = false;
          state.translationMappingSettings = action.payload;
        }
      )
      .addCase(fetchTranslationMappingSettings.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch translation mappings";
      })
      .addCase(fetchSecurityTypeMappingSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSecurityTypeMappingSettings.fulfilled,
        (state, action: PayloadAction<SecurityTypeMapping>) => {
          state.loading = false;
          state.securityTypeMappingSettings = action.payload;
        }
      )
      .addCase(fetchSecurityTypeMappingSettings.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch security type mappings";
      })
      .addCase(fetchAllEmailsofUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAllEmailsofUsers.fulfilled,
        (state, action: PayloadAction<UserEmails[]>) => {
          state.loading = false;
          state.emailsToImpersonate = action.payload;
        }
      )
      .addCase(fetchAllEmailsofUsers.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch admins";
      });
  },
});

export const {
  setSelectedEntityIds,
  resetSelectedEntityIds,
  setSelectedStrategyids,
  resetSelectedStrategyIds,
  setSelectedEntityBreadcrumb,
  setPortfolioDetails,
  setSelectedDate,
  setSelectedEntityNames,
  setSelectedStrategyState,
  setSelectedEmail,
  setOriginalEmail
} = dataSlice.actions;
export default dataSlice.reducer;
