import React, { useMemo } from "react";
import { Box, Flex, Text, Stack, useBreakpointValue } from "@chakra-ui/react";
import Chart from "react-google-charts";
import { GoogleChartWrapper } from "react-google-charts";
import { globalStyles } from "theme/styles";

type ChartProps = {
  chartData: any;
  onClick: (strategyName: string) => void;
};

const PieChart: React.FC<ChartProps> = ({ chartData, onClick }) => {
  const handleSelect = (chartWrapper: GoogleChartWrapper) => {
    const chart = chartWrapper?.getChart();

    if (chart) {
      const selection = chart.getSelection();

      if (selection.length) {
        const { row } = selection[0];
        const dataTable = chartWrapper.getDataTable();
        if (dataTable) {
          const strategyName = dataTable.getValue(row, 0).toString();
          onClick(strategyName);
        }
      }
    }
  };
  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );
  const chartAreaMargins = useBreakpointValue({
    sm: { left: 0, top: 0, right: 0, bottom: 0 },  
    md: { left: 0, top: 0, right: 0, bottom: 0 },  
    xl: { left: 10, top: 5, right: 10, bottom: 0 },  
  });
  const chartOptions: any = {
    chartArea: chartAreaMargins,
    tooltip: { trigger: 'none' }, 
    fontName: "Source Sans Pro",
    colors: globalStyles.customColors.all,
    legend: { position: "none" }, 
    pieSliceText: "none", 
  };

  const colors = chartOptions.colors || [];
  const responsiveWidth = useBreakpointValue({
    sm: "250px",    // for medium devices
    md: "400px",    // for larger screens
    lg: "330px",    // for even larger screens
  });
  
  const responsiveHeight = useBreakpointValue({
    sm: "280px",    // for medium devices
    md: "400px",    // for larger screens
    lg: "330px",    // for even larger screens
  });
  
  return (
    <Flex width="auto" direction="row" align="flex-start" wrap="wrap" >
<Box flex="1" width={responsiveWidth} height={responsiveHeight}>
  <Chart
    chartType="PieChart"
    data={chartData}
    options={chartOptions}
    width="100%"
    height="100%"
    chartEvents={[
      {
        eventName: "select",
        callback: (props) => {
          handleSelect(props.chartWrapper);
        },
      },
    ]}
  />
</Box>


      <Box flex="0 0 30%" ml={4} >
        <Stack direction="column" spacing={2} width={60} ml={5} mt={10}>
          {chartData.slice(1).map(([strategyName, value, tooltip]: any, index: React.Key) => (
            <Flex key={index} align="center">
              <Box
                width="20px"
                height="20px"
                bg={colors[index.toString()] || "gray.500"}
                borderRadius="md"
                mr={2}
              />
              <Text fontSize={13}>
              <span style={{ fontWeight: 'bold' }}>{strategyName}</span>: {currencyFormatter.format(value)}
              </Text>
            </Flex>
          ))}
        </Stack>
      </Box>
    </Flex>
  );
};
export default PieChart;
