// components/DatePickerSelect.tsx
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, useDisclosure } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setSelectedDate } from '../../redux/slices/dataSlice';

const DatePickerSelect: React.FC = () => {
  const { isOpen, onToggle, onClose } = useDisclosure(); // Use onToggle to toggle isOpen state
  const [selectedDate, setSelectedDateState] = useState<Date | null>(new Date());
  const dispatch = useDispatch();
  const formatDate = (date: Date | null) => {
    return date ? date.toLocaleDateString('en-GB') : '';
  };
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateString = date.toDateString();
      setSelectedDateState(date);
      dispatch(setSelectedDate(dateString)); 
      onClose();
    }
  };

  const handleButtonClick = () => {
    if (isOpen) {
      onClose(); 
    } else {
      onToggle(); 
    }
  };

  return (
    <>
      <Button onClick={handleButtonClick} sx={{ fontSize: 14, fontWeight: 'normal', position: 'relative' }}>
        {selectedDate ? formatDate(selectedDate) : 'Pick date'}
      </Button>
      {isOpen && (
        <div style={{ position: 'absolute', zIndex: 1 }}>
          <DatePicker
            selected={selectedDate}
            onClickOutside={onClose}
            onChange={handleDateChange}
            inline
            todayButton="Today"
            clearButtonTitle='Clear'
            clearButtonClassName='hello'
            maxDate={new Date()}
          />
        </div>
      )}
    </>
  );
};

export default DatePickerSelect;
