import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import SearchableComboBox from "components/searchableCombo/SearchableCombobox";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  fetchAllEmailsofUsers,
  setSelectedEmail,
} from "../../../redux/slices/dataSlice";

interface ImpersonatorProps {
  loggedInEmail: string;
}

const Impersonator: React.FC<ImpersonatorProps> = (
  props: ImpersonatorProps
) => {
  const dispatch = useDispatch<AppDispatch>();
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");

  let { emailsToImpersonate: userEmails } = useSelector(
    (state: RootState) => state.data
  );
  let originalEmail: string = useSelector(
    (state: RootState) => state.data.originalEmail
  );

  const isAdmin = useSelector((state: RootState) =>
    state.data.usersByOriginalEmail.some((x) => x.isAdmin)
  );

  const handleEmailChange = (email: string | null) => {
    // we need to clear this from the local storage, because it bugs when we impersonate and the role type changes.
    localStorage.removeItem("selectedRole");
    
    if (email) {
      dispatch(setSelectedEmail(email));
    } else {
      dispatch(setSelectedEmail(originalEmail));
    }
  };

  useEffect(() => {
    dispatch(fetchAllEmailsofUsers());
  }, [dispatch]);

  return isAdmin ? (
    <Box
      onClick={(e) => e.preventDefault()}
      borderBottom="1px solid"
      borderColor={borderColor}
    >
      <Flex flexDirection="column" pl="10px">
        <Text fontSize="sm">User impersonation</Text>
        <SearchableComboBox
          items={userEmails.map((x) => x.email).sort((a, b) => a.localeCompare(b))}
          handleItemSelection={handleEmailChange}
        ></SearchableComboBox>
      </Flex>
    </Box>
  ) : null;
};

export default Impersonator;
