import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { RootState, AppDispatch } from "../../../redux/store";
import {
  fetchusersByEmail,
  fetchEntitiesByEmail,
  setSelectedEntityIds,
  fetchStrategyMappingSettings,
  fetchStakeholders,
  fetchTranslationMappingSettings,
  fetchSecurityTypeMappingSettings,
  Entity,
} from "../../../redux/slices/dataSlice";

export default function RoleEntity() {
  const dispatch = useDispatch<AppDispatch>();
  let { users, entitiesbyEmail } = useSelector(
    (state: RootState) => state.data
  );

  const selectedEntityIds = useSelector(
    (state: RootState) => state.data.selectedEntityIds
  );
  const email = useSelector(
    (state: RootState) => state.data.selectedEmail ?? ""
  );
  const [selectedRole, setSelectedRole] = useState<string>(
    () => localStorage.getItem("selectedRole") || ""
  );

  useEffect(() => {
    // Check if users array is not null and has length greater than 0
    if (users && users.length > 0) {
      // Check if user has both Investor and Advisor roles
      const hasInvestorRole = users.some((user) => user.role === "Investor");
      const hasAdvisorRole = users.some((user) => user.role === "Advisor");

      if (hasInvestorRole && hasAdvisorRole) {
        setSelectedRole("Investor");
        console.log('rolwissel - 111')
      } else if (hasInvestorRole) {
        setSelectedRole("Investor");
        console.log('rolwissel - 222')
      } else if (hasAdvisorRole) {
        setSelectedRole("Advisor");
        console.log('rolwissel - 333')
      }
    }
  }, [users, email]);

  useEffect(() => {
    dispatch(fetchStrategyMappingSettings());
    dispatch(fetchTranslationMappingSettings());
    dispatch(fetchSecurityTypeMappingSettings());
    if (email) {
      dispatch(fetchusersByEmail(email));
    }
  }, [email, dispatch]);

  useEffect(() => {
    if (selectedRole) {
      dispatch(
        fetchEntitiesByEmail({
          userIds: users.map((x) => x.id),
          role: selectedRole,
        })
      );
    }
  }, [selectedRole, email, dispatch, users]);

  useEffect(() => {
    if (selectedEntityIds?.length > 0) {
      dispatch(fetchStakeholders({ ownerIds: selectedEntityIds }));
    }
  }, [selectedEntityIds, dispatch]);

  const getStoredEntities = useCallback((): number[] => {
    const storedEntities = localStorage.getItem("selectedEntities");
    if (storedEntities) {
      return JSON.parse(storedEntities) as number[];
    }
    return [];
  }, []);

  useEffect(() => {
    const storedRole = localStorage.getItem("selectedRole");
    if (storedRole) {
      setSelectedRole(storedRole);
      dispatch(
        fetchEntitiesByEmail({
          userIds: users.map((x) => x.id),
          role: storedRole,
        })
      );
    }

    const storedEntities = getStoredEntities();
    if (storedEntities) {
      dispatch(setSelectedEntityIds(storedEntities));
    }
  }, [dispatch, getStoredEntities, users]);

  useEffect(() => {
    if (entitiesbyEmail.length > 0) {
      // Sort entities alphabetically by entity name
      const sortedEntities = entitiesbyEmail
        .slice()
        .sort((a, b) => a.entityName.localeCompare(b.entityName));

      const entityIdsInStorage: number[] = getStoredEntities();

      const selectedEntities =
        sortedEntities?.filter((x) =>
          entityIdsInStorage.includes(Number(x.entityId))
        ) ?? [];

      setNewEntitiesToSelect(
        selectedEntities.length ? selectedEntities : sortedEntities
      );
    }

    function setNewEntitiesToSelect(sortedEntities: Entity[]) {
      if (selectedRole === "Investor") {
        // Get all entityId from the sorted list
        const entityIdsToSelect = sortedEntities.map((entity) =>
          Number(entity.entityId)
        );

        dispatch(setSelectedEntityIds(entityIdsToSelect));
        localStorage.setItem(
          "selectedEntities",
          JSON.stringify(entityIdsToSelect)
        );
        dispatch(fetchStakeholders({ ownerIds: entityIdsToSelect }));
      } else if (selectedRole === "Advisor") {
        // Get the first entityId from the sorted list
        const defaultEntityId =
          sortedEntities.length > 0 ? sortedEntities[0].entityId : null;

        if (defaultEntityId !== null) {
          const entityIdToSelect = Number(defaultEntityId);
          dispatch(setSelectedEntityIds([entityIdToSelect]));
          localStorage.setItem(
            "selectedEntities",
            JSON.stringify([entityIdToSelect])
          );
          dispatch(fetchStakeholders({ ownerIds: [entityIdToSelect] }));
        }
      }
    }
  }, [selectedRole, entitiesbyEmail, dispatch, getStoredEntities]);

  const handleRoleChange = (role: string | string[]) => {
    setSelectedRole(role as string);
    localStorage.setItem("selectedRole", role as string);
    dispatch(setSelectedEntityIds([]));
    localStorage.removeItem("selectedEntities");
  };

  const handleEntityChange = (entities: string | string[]) => {
    const selectedEntities = Array.isArray(entities)
      ? entities.map((id) => Number(id))
      : [Number(entities)];
    if (selectedEntities.length === 0) {
      return;
    }
    dispatch(setSelectedEntityIds(selectedEntities));
    localStorage.setItem("selectedEntities", JSON.stringify(selectedEntities));
  };
  const getEntityNames = (ids: number[]) => {
    return ids.map((id) => {
      const entity = entitiesbyEmail.find((e) => +e.entityId === id);

      return selectedRole === "Investor" && entity?.entityType === "PERSON"
        ? `${entity.name}, ${entity.firstName}`
        : entity?.entityName ?? "";
    });
  };

  // Sort entities alphabetically by entity name
  const sortedEntitiesbyEmail = entitiesbyEmail
    .slice()
    .sort((a, b) => a.entityName.localeCompare(b.entityName));
  const entityNames = getEntityNames(selectedEntityIds);
  const maxNameLength = 10;

  const truncateName = (name: string) => {
    return name.length > maxNameLength
      ? name.slice(0, maxNameLength) + "..."
      : name;
  };

  const truncatedEntityNames = entityNames.map(truncateName);
  const displayText =
    selectedEntityIds?.length === 0
      ? "Entities"
      : selectedEntityIds?.length === 1
      ? truncatedEntityNames[0]
      : "Consolidated";

  const uniqueEntities = useMemo(() => {
    const seenEntityNames = new Set();
    return sortedEntitiesbyEmail.filter((entity: any) => {
      if (seenEntityNames.has(entity.entityName)) {
        return false;
      }
      seenEntityNames.add(entity.entityName);
      return true;
    });
  }, [sortedEntitiesbyEmail]);
  return (
    <Box>
      <Menu closeOnSelect={true}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          width="100px"
          margin={1}
          padding={1}
        >
          {selectedRole ? selectedRole : "Role"}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            value={selectedRole}
            onChange={handleRoleChange}
            type="radio"
          >
            {Array.from(
              new Set(users.map((stakeholder: any) => stakeholder.role))
            ).map((role) => (
              <MenuItemOption key={role} value={role}>
                {role}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>

      <Menu closeOnSelect={false} isLazy={true}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          width="120px"
          margin={1}
          padding={1}
        >
          {displayText}
        </MenuButton>
        <MenuList>
          {selectedRole === "Investor" ? (
            <MenuOptionGroup
              onChange={(values) => handleEntityChange(values)}
              value={selectedEntityIds.map((x) => x.toString())}
              type="checkbox"
            >
              {sortedEntitiesbyEmail.map((entity) => (
                <MenuItemOption
                  key={entity.entityId}
                  value={entity.entityId.toString()}
                >
                  {entity.entityType === "PERSON"
                    ? `${entity.name}, ${entity.firstName}`
                    : entity.entityName}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          ) : (
            <MenuOptionGroup
              onChange={handleEntityChange}
              value={selectedEntityIds[0]?.toString()}
              type="radio"
            >
              {uniqueEntities.map((entity: Entity) => (
                <MenuItemOption
                  key={entity.entityId}
                  value={entity.entityId.toString()}
                >
                  {entity.entityName}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
}
