import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import StrategyCalculator from "utils/StrategyCalculator";
import { PagedTable } from "components/dataDisplay/PagedTable";

type RowObj = {
  strategy: string;
  combinedStrategy: string;
  initialInvestmentDate: Date;
  initialValue: number;
  initialValueSHL: number;
  paid: number;
  toPay: number;
  distributions: number;
  actualValue: number;
  totalValue: number;
  mm: number;
  numberOfSecurities: number;
  nav: number;
  shareValuesPrices?: { [key: string]: number };
};

export default function PortfolioDetails(props: {
  showHistoricalData: boolean;
}) {
  const { showHistoricalData } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );
  const currencyFormatter4Decimals = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 4,
      }),
    []
  );

  const securityTypeMappingSettings = useSelector(
    (state: RootState) => state.data.securityTypeMappingSettings
  );
  const securityClasses = useMemo(
    () =>
      securityTypeMappingSettings.securityTypes.filter((x) =>
        selectedStrategyIds.some((strategyId) =>
          x.strategyIds.includes(strategyId)
        )
      ),
    [securityTypeMappingSettings, selectedStrategyIds]
  );
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const numberFormatter = useMemo(() => new Intl.NumberFormat("nl-BE", {}), []);
  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("nl-BE", {}), []);

  const columnHelper = createColumnHelper<RowObj>();
  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );
  const [calculationDate, setCalculationDate] = useState<Date>(new Date());

  const CreatePortfolioDetailRow = (
    rows: { [key: string]: RowObj },
    key: string,
    data: RowObj,
    shareValue: number
  ) => {
    if (!rows[key]) {
      rows[key] = {
        strategy: data.strategy,
        combinedStrategy: data.combinedStrategy,
        initialInvestmentDate: data.initialInvestmentDate,
        initialValue: 0,
        initialValueSHL: 0,
        paid: 0,
        toPay: 0,
        distributions: 0,
        actualValue: 0,
        totalValue: 0,
        mm: 0,
        numberOfSecurities: 0,
        nav: shareValue,
      };
    }

    // these values we can just add.
    rows[key].initialValue += data.initialValue;
    rows[key].initialValueSHL += data.initialValueSHL;
    rows[key].distributions += data.distributions;
    rows[key].actualValue += data.actualValue;
    rows[key].paid += data.paid;
    rows[key].toPay += data.toPay;
    rows[key].totalValue += data.totalValue;
    rows[key].numberOfSecurities += data.numberOfSecurities;

    // the values we need to recalculate per row.
    rows[key].initialInvestmentDate =
      data.initialInvestmentDate < rows[key].initialInvestmentDate
        ? data.initialInvestmentDate
        : rows[key].initialInvestmentDate;
    rows[key].mm = +(
      rows[key].totalValue / (rows[key].initialValue as number)
    ).toFixed(2);
  };

  useEffect(() => {
    if (showHistoricalData) {
      const calculationDate = selectedDate
        ? new Date(selectedDate)
        : new Date();
      setCalculationDate(calculationDate);
    } else {
      setCalculationDate(new Date());
    }
  }, [selectedDate, showHistoricalData]);

  const [ungroupedRows, setUngroupedRows] = useState<RowObj[]>([]);
  const [groupedRows, setGroupedRows] = useState<RowObj[]>([]);

  useEffect(() => {
    var rows = selectedStrategyIds
      .filter((strategyId) =>
        strategyCalculator.doesStrategyApply(strategyId, stakeholders)
      )
      .map((strategyId) => {
        const strategyName = strategyCalculator.getStrategyNameById(strategyId);
        const combinedStrategyName =
          strategyCalculator.getCombinedNameByStrategyId(strategyId);
        const initialInvestmentDate =
          strategyCalculator.getOldestTransactionDate(
            stakeholders,
            [strategyId],
            calculationDate
          );
        const initialValue = strategyCalculator.calculateInitialValue(
          stakeholders,
          [strategyId],
          calculationDate
        );
        const initialValueSHL = strategyCalculator.calculateInitialLoanValue(
          stakeholders,
          [strategyId],
          calculationDate
        );
        const distributions = strategyCalculator.calculateGrossDistributions(
          stakeholders,
          [strategyId],
          calculationDate
        );
        const actualValue = strategyCalculator.calculateActualValue(
          stakeholders,
          [strategyId],
          calculationDate
        );
        const unpaidValue = strategyCalculator.calculateUnpaidValue(
          stakeholders,
          [strategyId],
          calculationDate
        );
        const paidValue = strategyCalculator.calculatePaidValue(
          stakeholders,
          [strategyId],
          calculationDate
        );
        const numberOfSecurities =
          strategyCalculator.calculateNumberOfSecurities(
            stakeholders,
            [strategyId],
            calculationDate
          );
        const shareValuePrices =
          strategyCalculator.getShareValuePricesPerSecurityClass(
            strategyId,
            stakeholders,
            calculationDate,
            securityClasses
          );

        const totalValue = actualValue + distributions;

        return {
          strategy: strategyName,
          combinedStrategy: combinedStrategyName,
          initialInvestmentDate: initialInvestmentDate,
          initialValue: initialValue,
          initialValueSHL: initialValueSHL,
          numberOfSecurities: numberOfSecurities,
          paid: paidValue,
          toPay: unpaidValue,
          distributions: distributions,
          actualValue: actualValue,
          totalValue: totalValue,
          mm: +(totalValue / (initialValue as number)).toFixed(2),
          nav: 0,
          shareValuesPrices: shareValuePrices,
        };
      })
      .filter((row) => row.initialInvestmentDate <= calculationDate); // Add this line to filter out rows with a later date.

    setUngroupedRows(rows);
  }, [selectedStrategyIds, strategyCalculator, stakeholders, calculationDate, securityClasses]);

  useEffect(() => {
    const rows: { [key: string]: RowObj } = {};
    const investmentsToHide = ["Commitment"];

    ungroupedRows.forEach((data) => {
      if (data.shareValuesPrices) {
        // We loop over the different share prices per security class. This means an investment can be added multiple times to the overview.
        Object.keys(data.shareValuesPrices).forEach((securityClass) => {
          const key = `${data.combinedStrategy}-${securityClass}`;
          const shareValue = data.shareValuesPrices[securityClass];

          CreatePortfolioDetailRow(rows, key, data, shareValue);
        });
      } else {
        const key = `${data.combinedStrategy}`;
        CreatePortfolioDetailRow(rows, key, data, 0);
      }
    });
    const sortedRows = Object.values(rows)
      .sort(
        (a, b) =>
          b.initialInvestmentDate.getTime() - a.initialInvestmentDate.getTime()
      )
      .filter((x) => !investmentsToHide.includes(x.combinedStrategy)); // filter the rows we do not want to see.

    setGroupedRows(sortedRows);
  }, [ungroupedRows]);

  const columns = [
    columnHelper.accessor("initialInvestmentDate", {
      id: "initialInvestmentDate",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Initial Investment Date
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {dateFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("combinedStrategy", {
      id: "combinedStrategy",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Investment
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("initialValue", {
      id: "initialValue",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Total Initial Investment
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("initialValueSHL", {
      id: "initialValueSHL",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Initial Value SHL
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("paid", {
      id: "paid",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Shares Paid Up
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("toPay", {
      id: "toPay",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Shares To Pay Up
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("distributions", {
      id: "distributions",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Distributions
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("numberOfSecurities", {
      id: "numberOfSecurities",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Number of shares
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {numberFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("nav", {
      id: "nav",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          NAV
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter4Decimals.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("actualValue", {
      id: "actualValue",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Actual Value
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="400">
            {currencyFormatter.format(info.getValue())}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("totalValue", {
      id: "totalValue",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Total Value
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("mm", {
      id: "mm",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          MM
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}x
        </Text>
      ),
    }),
  ];

  return (
    <PagedTable
      title="Portfolio Details"
      columns={columns}
      data={groupedRows}
    />
  );
}
