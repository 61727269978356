import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';

type ChartProps = {
    chartData: any[];
    chartOptions: any;
    width?: string | number;
    height?: string | number;
};

const LineChart: React.FC<ChartProps> = ({ chartData, chartOptions, width = '100%', height = '100%' }) => {
    const [chartSize, setChartSize] = useState({ width, height });

    useEffect(() => {
        const handleResize = () => {
            setChartSize({
                width: width === '100%' ? '100%' : width,
                height: height === '100%' ? '100%' : height,
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial call to set dimensions

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [width, height]);

    return (
            <Chart
               chartType="LineChart"
               data={chartData}
               options={chartOptions}
               width="100%"
            height="400px"
            />             
    
    );
};

export default LineChart;
