import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute";

import MainDashboard from "views/admin/default";
import AdminLayout from "layouts/admin";
import Reporting from "views/admin/reporting";

export const ROUTES = {
  HOME: "/",
  REPORTING: "/reporting"
};

const Router: FunctionComponent = () => (
  <Routes>
    <Route element={<ProtectedRoute />}>
      <Route path={ROUTES.HOME} element={<AdminLayout component={<MainDashboard />} />} />
      <Route path={ROUTES.REPORTING} element={<AdminLayout component={<Reporting />} />} />
    </Route>
  </Routes>
);

export default Router;
