import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { AppDispatch, RootState } from "../../../../redux/store";
import PieChart from "components/charts/PieChart";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  setSelectedStrategyids,
  resetSelectedStrategyIds,
  setSelectedEntityBreadcrumb,
  setSelectedStrategyState,
} from "../../../../redux/slices/dataSlice";
import _ from "lodash";
import StrategyCalculator from "utils/StrategyCalculator";
import DatePickerSelect from "components/calendar/DatePickers";

interface ActualValueProps {
  showHistoricalData: boolean;
}

const ActualValue: React.FC<ActualValueProps> = (props: ActualValueProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showHistoricalData, ...rest } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { loading } = useSelector((state: RootState) => state.data);
  const [calculationDate, setCalculationDate] = useState<Date>(new Date());

  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const selectedEntityBreadcrumb = useSelector(
    (state: RootState) => state.data.selectedEntityBreadcrumb
  );
  const selectedStrategyState = useSelector(
    (state: RootState) => state.data.selectedStrategyState
  );
  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );
  const [breadcrumbTrail, setBreadcrumbTrail] = useState<string[]>(
    selectedEntityBreadcrumb
  );

  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const [strategyValues, setStrategyValues] = useState<{
    [key: string]: number;
  }>();

  const [pieChartData, setPieChartData] = useState<any>([
    ["Strategy", "Actual Value", { role: "tooltip" }],
  ]);
  
  useEffect(() => {
    const handleResize = () => window.dispatchEvent(new Event("resize"));

    handleResize(); // Ensure proper resizing after component mounts or data changes
    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pieChartData]);
  const [strategyState, setStrategyState] = useState<{
    strategyName: string;
    level: number;
  }>(selectedStrategyState);

  useEffect(() => {
    dispatch(setSelectedEntityBreadcrumb(breadcrumbTrail));
  }, [breadcrumbTrail, dispatch]);

  useEffect(() => {
    dispatch(setSelectedStrategyState(strategyState));
  }, [strategyState, dispatch]);

  useEffect(() => {
    if (showHistoricalData) {
      const calculationDate = selectedDate
        ? new Date(selectedDate)
        : new Date();
      setCalculationDate(calculationDate);
    } else {
      setCalculationDate(new Date());
    }
  }, [selectedDate, showHistoricalData]);

  useEffect(() => {
    if (strategyValues) {
      const data: (string | number | object)[][] = [];
      data.push(["Strategy", "Actual Value", { role: "tooltip" }]);
      Object.keys(strategyValues).forEach((strategyName) => {
        data.push([
          strategyName,
          strategyValues[strategyName],
          `${strategyName}: ${currencyFormatter.format(
            strategyValues[strategyName]
          )}`,
        ]);
      });
      if (data.length > 1) {
        setPieChartData(data);
      }
    }
  }, [
    currencyFormatter,
    dispatch,
    strategyValues,
    loading,
    stakeholders,
    strategyMappingSettings,
    selectedStrategyIds,
    strategyCalculator,
  ]);

  useEffect(() => {
    if (strategyState) {
      const strategyValues: { [key: string]: number } = {};

      console.log(`--- START OF PIE CHART ACTUAL VALUE CALCULATION ---`);

      stakeholders?.forEach((st) => {
        // Shares calculation
        st.shares?.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }
          const value = strategyCalculator.calculateActualShareValue(
            share,
            calculationDate
          );

          if (value) {
            let strategyName = "";
            switch (strategyState.level) {
              case 0:
                strategyName = strategyCalculator.getStrategyNameById(
                  share.strategyId
                );
                break;
              case 1:
                strategyName = strategyCalculator.getCombinedNameByStrategyId(
                  share.strategyId
                );
                break;
              case 2:
                strategyName = strategyCalculator.getDetailedNameByStrategyId(
                  share.strategyId
                );
                break;
            }

            if (strategyName) {
              if (!strategyValues[strategyName]) {
                strategyValues[strategyName] = 0;
              }
              strategyValues[strategyName] += value;
            }
          }
        });

        // we group the loans by strategy id, because there can be multiple loans which are actually still the same loan.
        // The same loans are indicated by the same strategy id.
        // For calculating the actual value, we group the loans and take the loan with the latest startDate.
        const actualLoans = _.chain(st.loans)
          .groupBy((x) => x.strategyId)
          .map((loans, strategyId) => ({
            strategyId,
            loan: _.maxBy(loans, (loan) => new Date(loan.startDate)),
          }))
          .value()
          .map((x) => x.loan);

        actualLoans?.forEach((loan) => {
          if (!selectedStrategyIds.includes(loan.strategyId)) {
            return;
          }
          const loanValue = strategyCalculator.calculateActualLoanValue(
            loan,
            calculationDate
          );

          if (loanValue) {
            let strategyName = "";
            switch (strategyState.level) {
              case 0:
                strategyName = strategyCalculator.getStrategyNameById(
                  loan.strategyId
                );
                break;
              case 1:
                strategyName = strategyCalculator.getCombinedNameByStrategyId(
                  loan.strategyId
                );
                break;
              case 2:
                strategyName = strategyCalculator.getDetailedNameByStrategyId(
                  loan.strategyId
                );
                break;
            }

            if (strategyName) {
              if (!strategyValues[strategyName]) {
                strategyValues[strategyName] = 0;
              }
              strategyValues[strategyName] += loanValue;
            }
          }
        });
      });

      console.log(`--- END OF PIE CHART ACTUAL VALUE CALCULATION ---`);

      setStrategyValues(strategyValues);
    }
  }, [
    selectedStrategyIds,
    stakeholders,
    strategyCalculator,
    calculationDate,
    strategyState,
  ]);

  const handleClick = useCallback((strategyName: string) => {
    setStrategyState((prevState) => {
      const newLevel = Math.min(prevState.level + 1, 2); // 2 is the maximum level, the min function will take the lowest number.

      if (prevState.level < 2) {
        setBreadcrumbTrail((previousBreadcrumb) => {
          if (
            previousBreadcrumb.length &&
            previousBreadcrumb[previousBreadcrumb.length - 1] !== strategyName
          ) {
            return [...previousBreadcrumb, strategyName];
          } else {
            return previousBreadcrumb;
          }
        });
      }

      return {
        strategyName,
        level: newLevel,
      };
    });
  }, []);

  useEffect(() => {
    if (strategyState && strategyMappingSettings) {
      if (strategyState?.level === 1) {
        const strategyDetail = strategyMappingSettings.values.find(
          (strategy) => strategy.strategy === strategyState.strategyName
        );

        if (strategyDetail) {
          const newStrategyIds = strategyDetail.combined
            .flatMap((x) => x.values)
            .map((x) => x.entityId);

          dispatch(setSelectedStrategyids(newStrategyIds));
        }
      } else {
        const strategyDetail = strategyMappingSettings.values
          .flatMap((x) => x.combined)
          .find((combined) => combined.name === strategyState.strategyName);

        if (strategyDetail) {
          const newStrategyIds = strategyDetail.values.map((x) => x.entityId);

          dispatch(setSelectedStrategyids(newStrategyIds));
        }
      }
    }
  }, [dispatch, strategyState, strategyMappingSettings]);

  const handleAllStrategiesClick = () => {
    setStrategyState({
      level: 0,
      strategyName: "",
    });
    setBreadcrumbTrail(["All strategies"]);
    dispatch(resetSelectedStrategyIds());
  };

  const handleBreadcrumbClick = (label: string, index: number) => {
    if (index === 0) {
      handleAllStrategiesClick();
    } else {
      setStrategyState((prevState) => {
        setBreadcrumbTrail((previousBreadcrumb) => {
          return [...previousBreadcrumb.slice(0, index + 1)];
        });

        return {
          strategyName: label,
          level: index,
        };
      });
    }
  };

  return (
    <Card
    alignItems="center"
    flexDirection="column"
    w="100%"
    h="100%"
    {...rest}
      
    >
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Actual Value
        </Text>

        {showHistoricalData ? (
          <Box mr={5}>
            <DatePickerSelect />
          </Box>
        ) : null}

        <Breadcrumb gap={2} p={2}>
          {breadcrumbTrail?.map((label, index) => (
            <BreadcrumbItem key={index} fontSize="sm" mb="5px">
              <BreadcrumbLink
                href="#"
                onClick={() => handleBreadcrumbClick(label, index)}
              >
                <Text>{label}</Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Flex>
      <PieChart chartData={pieChartData} onClick={handleClick} />
    </Card>
  );
};

export default ActualValue;
