import { Box, SimpleGrid } from "@chakra-ui/react";
import ActualValueChart from "views/admin/default/components/ActualValueChart";
import ReturnValue from "../default/components/ReturnChart";
import TransactionDetails from "./components/TransactionDetails";
import SHLOverview from "./components/SHLOverview";
import { useEffect } from "react";
import { msalInstance } from "index";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { setSelectedEmail } from "../../../redux/slices/dataSlice";

export default function Overview() { 
  return (
    <Box pt={{ base: "140px", md: "80px", xl: "130px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ActualValueChart showHistoricalData={true} />
        <ReturnValue showHistoricalData={true} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <TransactionDetails showHistoricalData={true} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <SHLOverview showHistoricalData={true} />
      </SimpleGrid>
    </Box>
  );
}
